.migrator {
  font-family: var(--brandType);
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .migrator {
    margin: 0 30px 0 100px;
  }
}

.heading {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  line-height: var(--lh-1);
  color: var(--mono-5);
  margin: 80px 0 12px 0;
  text-align: left;
}

.content {
  font-size: var(--fs-m);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 12px 0 0 0;
  text-align: left;
}

.container {
  box-shadow: var(--box-shadow-0);
  background-color: var(--white);
  margin-top: 24px;
  padding: 3rem 5rem 5rem 5rem;
  overflow: visible;
}

.result-heading {
  font-family: inherit;
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin: 0 0 1rem 0;
}

.result-heading div {
  display: inline-block;
  width: 50%;;
}

.result-heading #heading {
  font-size: var(--fs-l);
}

.result-heading #download {
  text-align: right;
}

.result-heading a {
  text-decoration: none;
  color: var(--brand-3);
  font-size: var(--fs-s);
  cursor: pointer;
}

.result-heading a:active {
  text-shadow: 1px 1px 2px var(--mono-3);
}

.result-heading a:focus {
  outline: none;
  border: none;
  outline-style: none;
  -moz-outline-style: none;
}


.result-statistics {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border-bottom: 1px solid var(--mono-3);
  padding: 1rem 0 2rem 0;
}

.result-statistics > div {
  width: 30%;
  flex: 0 1 auto;
}

.statistic-heading {
  color: var(--mono-4);
  font-size: var(--fs-s);
}

.statistic-reading {
  color: var(--mono-5);
  font-weight: var(--fw-bold);
  font-size: 32px;
}

#success-reading, #fail-reading {
  height: 12px;
  width: 12px;
  display: inline-block;
  margin: 0 0 5px 10px;
  border-radius: 12px;
}

#success-reading {
  background-color: var(--success);
}

#fail-reading {
  background-color: var(--danger);
}

.result-messages  {
  width: 70%;
}

.error-message {
  margin-left: 1.25em;
  font-family: inherit;
  font-size: var(--fs-s);
}

.error-metadata {
  margin-left: 1em;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  font-family: inherit;
  font-size: var(--fs-s);
}

.error-level{
  font-weight: var(--fw-semi-bold);
  font-size: var(--fs-s);
}

.error-list,
.warning-list,
.success-list {
  list-style: none;
  color: var(--mono-4);
}

.error-list li,
.warning-list li,
.success-list li{
  margin-bottom: 1.5em;
}

.error-list li::before {
  color: var(--danger);
}

.warning-list li::before {
  color: var(--warning);
}

.success-list li::before {
  color: var(--success);
}

.error-list li::before,
.warning-list li::before,
.success-list li::before {
  content: "\2B24";
  font-size: var(--fs-xxs);
  font-weight: var(--fw-bold);
  display: inline-block;
  margin-left: -1.5em;
}
